import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useAlerts } from "@acin.reactjs/alert";
import { scroller } from 'react-scroll';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import ReCAPTCHA from "react-google-recaptcha";
import * as yup from 'yup';
import Options from '../free-trail/form/CountryOption';
import PricesFormStyles from './PricesForm.styles';
import { postRequest } from '../../requestComponent/postRequest';
import { getRequestAPI } from '../../requestComponent/getRequest';
import { Select } from '../../select';
import { constants } from '../../../global-constants';

type Inputs = {
  email?: string;
  name?: string;
  password?: string;
  phone?: string;
  country?: string;
  terms?: boolean;
  termsPolicy?: boolean;
  entityType?: string;
  workers?: string;
};

interface PricesFormProps {
  onSubmitForm: () => void;
};

const PricesForm: FC<PricesFormProps> = ({
  onSubmitForm,
}) => {
  const localStorageIdokUrl = window.localStorage.getItem('idokUrl');
  const baseUrl = localStorageIdokUrl || "";
  const emptyOption = [{id:'', name:''}];
  const classes = PricesFormStyles();
  const { t, i18n } = useTranslation();
  const { error } = useAlerts();
  const [countryList, setCountryList] = useState(emptyOption);
  const [workersList, setWorkersList] = useState(emptyOption);
  const [entityList, setEntityList] = useState(emptyOption);
  const recaptcha = useRef();
  const hasReCaptchaToken = Boolean(import.meta?.env?.VITE_CAPTCHA_KEY);

  const {
    links: { terms },
    languages,
  } = constants;

  useEffect(() => {
    const source = '10';
    const errorRequest = error => {
      console.log(error);
    };

    // Country list
    getRequestAPI('v1/type/countries?limit=300', {
      source,
      successRequest: (response) => {
        const countries = response.data.data;
        setCountryList([
          ...countryList,
          ...countries
        ]);
      },
      errorRequest,
    });

    // Worker list
    getRequestAPI('v1/notification/customerSupport/quantityCollaboratorNumbers', {
      source,
      successRequest: (response) => {
        const workers = response.data.data;
        setWorkersList([
          ...workersList,
          ...workers
        ]);
      },
      errorRequest,
    });

    // Entity List
    getRequestAPI('v2/notification/customerSupport/entityTypes', {
      source,
      successRequest: (response) => {
        const newEntityList: [{id: string, name: string}] = response.data.data;

        setEntityList([
          ...entityList,
          ...newEntityList
        ]);
      },
      errorRequest,
    });
    // eslint-disable-next-line
  }, []);

  // Validation Messages
  const validationFreeTrialForm = yup.object().shape({
    name: yup.string().required(''),
    email: yup.string().email(t('validEmail')).required(''),
    phone: yup.number().typeError(''),
    country: yup.string().required(''),
    entityType: yup.string().required(''),
    workers: yup.string().required(''),
    terms: yup.bool().oneOf([true], t('termsRequired')),
    termsPolicy: yup.bool().oneOf([true], t('policyRequired'))
  });

  const { register, handleSubmit, errors } = useForm<Inputs>({
    resolver: yupResolver(validationFreeTrialForm),
  });

  const handleSubmitted = useCallback((data) => {
    const newData = {
      email: data.email,
      countryId: data.country,
      customerSupportTypeId: '2',
      name: data.name,
      phone: data.phone,
      acceptanceRules: data.terms,
      acceptancePolicy: data.termsPolicy,
      quantityCollaboratorId: data.workers,
      entityTypeId: data.entityType,
    };

    const recaptchaValue = recaptcha?.current?.getValue();
    const isValidCaptcha = (recaptchaValue && hasReCaptchaToken) || !hasReCaptchaToken;

    const successRequest = response => {
      if (!response.data.status && isValidCaptcha) {
        error(
          t('Não foi possível processar o seu pedido'),
          {title: t('Erro') + '!'}
        );
        return;
      };
      onSubmitForm();
      scroller.scrollTo('prices' , {smooth: true})
    };
    const errorRequest = error => {
      console.log(error);
    };

    if(!isValidCaptcha){
      error(
        t('O reCAPTCHA não foi completo'),
        { title: t('Erro') + '!' }
      );
      return;
    }

    postRequest('v1/notification/customerSupport', {
      postData: newData,
      source: '10',
      successRequest,
      errorRequest,
    });
  },[onSubmitForm, error, t]);

  return (
    <Grid container className={classes.formContainer}>
      <div className={classes.formText}>
        <Typography variant="body1" className={classes.text} >
          {t('priceFormText')}
        </Typography>
      </div>
      <Grid item xs={12} className={classes.form}>
        <form
          noValidate
          onSubmit={handleSubmit(handleSubmitted)}
          autoComplete="off"
        >
          <TextField
            required
            inputRef={register}
            helperText={errors.name ? errors.name.message : ''}
            error={!!errors.name}
            className={classes.inputText}
            size="small"
            name="name"
            label={t('entity')}
            InputLabelProps={{
              style: { paddingTop: '2px' },
            }}
            variant="outlined"
            fullWidth
          />
          <TextField
            required
            inputRef={register}
            helperText={errors.email ? errors.email.message : ''}
            error={!!errors.email}
            size="small"
            className={classes.inputText}
            name="email"
            label={t('email')}
            InputLabelProps={{
              style: { paddingTop: '2px' },
            }}
            variant="outlined"
            fullWidth
          />
          <TextField
            required
            inputRef={register}
            helperText={errors.phone ? errors.phone.message : ''}
            error={!!errors.phone}
            size="small"
            name="phone"
            label={t('phone')}
            InputLabelProps={{
              style: { paddingTop: '2px' },
            }}
            variant="outlined"
            className={classes.specialInputText}
          />
          <Select
            required
            name="country"
            label={t('country')}
            inputRef={register}
            helperText={errors.country ? errors.country.message : ''}
            error={!!errors.country}
            formControlProps={{
              fullWidth: false,
              className: classes.selectText
            }}
          >
            <Options
              options={countryList}
            />
          </Select>
          <Select
            required
            name="workers"
            label={t('workers')}
            inputRef={register}
            helperText={errors.workers ? errors.workers.message : ''}
            error={!!errors.workers}
            formControlProps={{
              fullWidth: false,
              className: classes.specialInputText
            }}
          >
            <Options
              options={workersList}
            />
          </Select>
          <Select
            required
            name="entityType"
            label={t('entityType')}
            inputRef={register}
            helperText={errors.entityType ? errors.entityType.message : ''}
            error={!!errors.entityType}
            formControlProps={{
              fullWidth: false,
              className: classes.inputText
            }}
          >
            <Options
              options={entityList}
            />
          </Select>
          <Typography variant="body1" className={classes.requiredField} >
            <span>*</span> {t('requiredFields')}
          </Typography>
          <FormControl className={classes.checkboxContainer} name="terms" error={!!errors.terms} component="fieldset">
            <FormControlLabel
              control={<Checkbox name="terms" inputRef={register} />}
              label={
                <Typography className={classes.label}>
                  {t('readTerms')}
                  <a href={`${terms}_${languages[i18n.language].toLowerCase() || 'pt'}.pdf`} target="_blank" rel="noopener noreferrer">
                    <span>
                      {t('conditionsTerms')}
                    </span>.
                  </a>
                </Typography>
              }
            />
            <FormHelperText>{errors.terms ? t('termsRequired') : ''}</FormHelperText>
          </FormControl>
          <FormControl className={classes.checkboxContainer} name="termsPolicy" error={!!errors.termsPolicy} component="fieldset">
            <FormControlLabel
              control={<Checkbox name="termsPolicy" inputRef={register} />}
              label={
                <Typography className={classes.label}>
                  {t('registerTerms')}
                  <a href={`${t('policyLink')}`} target="_blank" rel="noopener noreferrer">
                    <span>
                      {t('privacyPolicy')}
                    </span>.
                  </a>
                </Typography>
              }
            />
            <FormHelperText>{errors.termsPolicy ? t('policyRequired') : ''}</FormHelperText>
          </FormControl>
          <div className={hasReCaptchaToken ? classes.buttonSubmitContainerWithCaptcha : classes.submitButton}>
            <Button variant='contained' type='submit'>
              { t('request') }
            </Button>
            {hasReCaptchaToken &&
                <div>
                  <ReCAPTCHA ref={recaptcha} sitekey={import.meta.env.VITE_CAPTCHA_KEY} />
                </div>
              }
          </div>
        </form>
      </Grid>
    </Grid>
  )
};

export default PricesForm;
