import React, { useRef } from 'react';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import ThreeSquares from '../../../assets/icons/group13426.svg';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckCircle from '../../../assets/images/checkCircle.svg';
import newsletterStyle from './NewsletterComponent.styles';
import { postRequest } from '../../requestComponent/postRequest';
import { useAlerts } from '@acin.reactjs/alert';
import ReCAPTCHA from "react-google-recaptcha";
import { constants } from '../../../global-constants';

type Inputs = {
  email: string;
  terms: boolean;
  termsPolicy: boolean;
  newsletter?: boolean;
};

function Newsletter({ boxPosition }) {
  const localStorageIdokUrl = window.localStorage.getItem('idokUrl');
  const baseUrl = localStorageIdokUrl || "";
  const classes = newsletterStyle();
  const { t: translation, i18n } = useTranslation();
  const theme = useTheme();
  const mobilLayout = useMediaQuery(theme.breakpoints.down('xs'));
  const { error } = useAlerts();
  const recaptcha = useRef();
  const hasReCaptchaToken = Boolean(import.meta?.env?.VITE_CAPTCHA_KEY);

  const {
    links: { terms },
    languages
  } = constants;

  // Validation messages
  const validationNewsletterForm = yup.object().shape({
    email: yup.string().email(translation('validEmail')).required(translation('emailRequired')),
    terms: yup.bool().oneOf([true], translation('termsRequired')),
    termsPolicy: yup.bool().oneOf([true], translation('policyRequired')),
    newsletter: yup.bool(),
  });

  const { register, handleSubmit, errors } = useForm<Inputs>({
    resolver: yupResolver(validationNewsletterForm),
  });
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  const successRequest = (response) => {
    const recaptchaValue = recaptcha?.current?.getValue();
    const isValidCaptcha = (hasReCaptchaToken && recaptchaValue) || !hasReCaptchaToken;

    if(!isValidCaptcha){
      error(
        translation('O reCAPTCHA não foi completo'),
        { title: translation('Erro') + '!' }
      );
      return;
    }
    if (response.data.status && isValidCaptcha) {
      setFormSubmitted(true);
    } else {
      error(
        translation('Não foi possível processar o seu pedido'),
        { title: translation('Erro') + '!' }
      );
    };
  };

  const errorRequest = (error_response) => {
    console.log(error_response);
    error(
      translation('Não foi possível processar o seu pedido'),
      { title: translation('Erro') + '!' }
    );
  };

  const onSubmitted = (data) => {
    const recaptchaValue = recaptcha?.current?.getValue();
    const isValidCaptcha = (hasReCaptchaToken && recaptchaValue) || !hasReCaptchaToken;

    if(!isValidCaptcha){
      error(
        translation('O reCAPTCHA não foi completo'),
        { title: translation('Erro') + '!' }
      );
      return;
    }

    postRequest('v1/newsletters/subscribe', {
      postData: data,
      source: '10',
      successRequest,
      errorRequest,
    });
  };

  const successComponent = (
    <Grid item xs={12}>
      <Grid item xs={12} className={classes.successImage}>
        <img src={CheckCircle} alt="Success" title="Success" />
      </Grid>
      <Grid item xs={12} className={classes.successTextContent}>
        <Typography variant="body1" className={classes.successText}>
          {translation('thanks')}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.container} title="newsletter">
      <Grid item md={6}>
        {boxPosition === 'left' && <div className={classes.greenDivLeft} />}
      </Grid>
      <Grid item md={6}>
        {boxPosition === 'right' && <div className={classes.greenDivRight} />}
      </Grid>
      <Grid item xs={12} className={`${classes.containerSubscription} container-subscription`}>
        <Grid item xs={12} lg={9} className={classes.subscription}>
          <div className={classes.rail}>
            <div className={classes.dataContent}>
              <div className={classes.wrapperTitle}>
                <Typography variant="overline" component="h3" className={classes.title}>
                  <span className={classes.wrapperFirstLetter}>{translation('normalTitleNewsletter')} </span>
                  <span className={classes.textHighlight}>{translation('highlightTitleNewsletter')}</span>
                </Typography>
              </div>
              {formSubmitted ?
                successComponent :

                (<form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmitted)}  >
                  <Grid container className={classes.textContent}>
                    <Grid item xs={12} className={classes.paddingRight}>
                      <TextField
                        id='emailInput'
                        type='email'
                        inputRef={register}
                        size="small"
                        name="email"
                        label={translation('email')}
                        variant="outlined"
                        helperText={errors.email ? errors.email.message === 'Email obrigatório' ? translation('emailRequired') : translation('validEmail') : ''}
                        InputLabelProps={{
                          style: { paddingTop: '2px' },
                          htmlFor: 'emailInput'
                        }}
                        error={!!errors.email}
                        fullWidth
                        className={classes.titleMargin}
                      />

                      {
                        !mobilLayout &&
                        <Button size="small" color="inherit" type="submit" className={classes.subscribe}>
                          {translation('subscribe')}
                        </Button>
                      }
                    </Grid>
                    {
                      mobilLayout &&
                      <Grid item xs={12} className={classes.paddingRight}>
                        <Button size="small" color="inherit" type="submit" className={classes.subscribe}>
                          {translation('subscribe')}
                        </Button>
                      </Grid>
                    }

                    <Grid container className={classes.checkContainers}>
                      <Grid item xs={12}>
                        <FormControl name="terms" error={!!errors.terms} component="fieldset">
                          <FormControlLabel
                            control={<Checkbox name="terms" inputRef={register} />}
                            label={
                              <Typography className={classes.label}>
                                {translation('readTerms')}
                                <a href={`${terms}_${languages[i18n.language].toLowerCase() || 'pt'}.pdf`} target="_blank" rel="noopener noreferrer">
                                  <span>
                                    {translation('conditionsTerms')}
                                  </span>
                                </a>.
                              </Typography>
                            }
                            className={classes.checkbox}
                          />
                          <FormHelperText>{errors.terms ? translation('termsRequired') : ''}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl name="termsPolicy" error={!!errors.termsPolicy} component="fieldset">
                          <FormControlLabel
                            control={<Checkbox name="termsPolicy" inputRef={register} />}
                            label={
                              <Typography className={classes.label}>
                                {translation('registerTerms')}
                                <a href={`${translation('policyLink')}`} target="_blank" rel="noopener noreferrer">
                                  <span>
                                    {translation('privacyPolicy')}
                                  </span>
                                </a>.
                              </Typography>
                            }
                            className={classes.checkbox}
                          />
                          <FormHelperText>{errors.termsPolicy ? translation('policyRequired') : ''}</FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {hasReCaptchaToken &&
                      <div>
                        <ReCAPTCHA ref={recaptcha} sitekey={import.meta.env.VITE_CAPTCHA_KEY} />
                      </div>
                    }
                  </Grid>
                </form>)
              }
            </div>
          </div>
          <Grid item xs={12}>
            <img width='114px' height='94px' src={ThreeSquares} className={classes.squareImg} alt="Three Squares" title="Three Squares" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Newsletter;
